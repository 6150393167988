import React from "react";
import logo from '../assets/GiniLogo.svg';
import logoDark from '../assets/GiniLogoDark.svg';
import { HashLink } from 'react-router-hash-link';
import { NavContainerTop, NavElementWrapper, NavElement } from "../styles/NavContainer";

const Nav = (props) => {
  return (
    <NavContainerTop>
        <HashLink smooth to='/#about'>
          <NavElement>
            About/Jobs
          </NavElement>
        </HashLink>
        <HashLink smooth to='/#hero'>
          {props.isDarkTheme ? (
              <NavElement>
                <img src={logo} />
              </NavElement>
            ) : (
              <NavElement>
                <img src={logoDark} />
              </NavElement>
            )
          }
        </HashLink>
        <HashLink smooth to='/#portfolio'>
          <NavElement>
            Portfolio
          </NavElement>
        </HashLink>
    </NavContainerTop>
  )
};

export default Nav;