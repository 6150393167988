import React from "react";
import { BlogSection, 
  BlogSectionText,
  BlogSectionArticleContainer
 } from "../styles/BlogSection";
 import BlogSectionArticle from "./BlogSectionArticle";


const Blog = () => {
  return (
    <BlogSection id="blog">
      <BlogSectionText>
        Latest blog posts
      </BlogSectionText>
      <BlogSectionArticleContainer>
        <BlogSectionArticle
        date={"21.09.2022"}
        title={"Web3 Is Better Than Free"}
        url={"betterthanfree"}
        />
        <BlogSectionArticle
        date={"21.09.2022"}
        title={"Permissionless Innovation Can't Be Outrun"}
        url={"permissionless"}
        />
        <BlogSectionArticle
        date={"17.07.2022"}
        title={"Trust And Incentives Eat Politics"}
        url={"trust"}
        />
        <BlogSectionArticle
        date={"05.04.2022"}
        title={"The Launch of Gini Fund I: We Double Down on Crypto"}
        url={"launch"}
        />
      </BlogSectionArticleContainer>
    </BlogSection>
  )
}

export default Blog;