import styled from "styled-components";

const CopyrightSection = styled.div`
  height: 150px;
  background-color: rgb(116, 81, 220);
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 300px;
  @media (min-width: 370px) {
    padding-top:0;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 80px;
  }
`

const CopyrightDisclosure = styled.a`
  color: ${props => props.theme.disclosurecolor};
  cursor: pointer;
`

const CopyRightLogo = styled.div`
  img {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
`

const CopyrightIcons = styled.div`
  a {
    margin-right: 20px;
  }
`

export { CopyrightSection,
  CopyrightDisclosure,
  CopyRightLogo,
  CopyrightIcons
 };