import styled from "styled-components";

const VlogContainer = styled.div`
  margin-top: 200px;
  max-width: 700px;
  @media (max-width: 575px) {
    margin-top: 110px;
  }
`
const VlogTitle = styled.div`
  margin-bottom: 25px;
  font-style: normal;
  font-weight: bold;
  font-size: 30.57px;
  line-height: 116%;
  letter-spacing: -0.02em;
`

const VlogDateAuthor = styled.div`
  margin-bottom: 50px;
  font-size: 13px;
  line-height: 116%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgb(116, 81, 220);
`

const VlogContent = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.3;
`

const VlogFigure = styled.figure`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
  img {
    max-width: 100%;
  }
`

const VlogParagraph = styled.p`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

const VlogHeadline3 = styled.h3`
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`

const VlogHeadline2 = styled.h2`
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
`

const VlogOl = styled.ol`
  list-style-position: inside;
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
`

const VlogBottom = styled.div`
  border-top: 1px solid rgb(116, 81, 220);
  margin-top: 50px;
  padding-top: 50px;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
`

const VlogDisclosures = styled.a`
  display: block;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 200px;
  color: rgb(255, 255, 255);
  @media (max-width: 575px) {
    padding-bottom: 110px;
  }
`

export { VlogContainer, 
  VlogTitle, 
  VlogDateAuthor,
  VlogContent,
  VlogFigure,
  VlogParagraph,
  VlogHeadline3,
  VlogHeadline2,
  VlogOl,
  VlogBottom,
  VlogDisclosures
};