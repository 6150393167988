import React from "react";
import { useTheme, useThemeUpdate } from "./ThemeContext";
import { HeroSection, HeaderText, HeaderTextTwo, HeaderTextThree } from "../styles/HeroSection";

const Hero = () => {
  const liquidTheme = useTheme()
  const toggleTheme = useThemeUpdate()
  return (
    <HeroSection id="hero">
      <HeaderText>
        {liquidTheme 
          ? <div>
              We back <span onClick={toggleTheme}>liquid protocols</span> <br/>
              <span>enabling</span> the <span>tokenized</span> <br/>
              internet <span>revolution</span>
            </div>
          : <div>
              We back <span onClick={toggleTheme}>liquid protocols</span> <br/>
              <span>enabling</span> the <span>tokenized</span> <br/>
              internet <span>revolution</span>
            </div>
        }
      </HeaderText>
      <HeaderTextTwo>
        {liquidTheme 
          ? <div>
              We back <span onClick={toggleTheme}>liquid protocols</span> <br/>
              <span>enabling</span> the <span>tokenized</span> <br/>
              internet <span>revolution</span>
            </div>
          : <div>
              We back <span onClick={toggleTheme}>liquid protocols</span> <br/>
              <span>enabling</span> the <span>tokenized</span> <br/>
              internet <span>revolution</span>
            </div>
        }
      </HeaderTextTwo>
      <HeaderTextThree>
        {liquidTheme 
          ? <div>
              We back <span onClick={toggleTheme}>liquid protocols</span> <br/>
              <span>enabling</span> the <span>tokenized</span> <br/>
              internet <span>revolution</span>
            </div>
          : <div>
              We back <span onClick={toggleTheme}>liquid protocols</span> <br/>
              <span>enabling</span> the <span>tokenized</span> <br/>
              internet <span>revolution</span>
            </div>
        }
      </HeaderTextThree>
    </HeroSection>
  )
}

/*
<div>
  The <span onClick={toggleTheme}>global</span> transition <br/>
  to <span>decentralized</span> <br/>
  technology requires <br/>
  new <span>protocols</span>
</div>
: <div>
  We back <span onClick={toggleTheme}>bold</span> entrepreneurs <br/>
  <span>building</span> the <span>tokenized</span> <br/>
  internet revolution
</div>


*/

export default Hero;