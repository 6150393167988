import styled from "styled-components";

const ReachOutSection = styled.div`
  min-height: calc((var(--vh,1vh) * 100) - (75px * 2));
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 80px;
`

const ReachOutContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  margin-top: 50px;
  margin-bottom: 50px;
  gap: 50px;
  overflow: hidden;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
  svg {
    justify-content: center;
    margin: auto;
  }
  img {
    margin: 20px;
    width: 250px;
    height: 250px;
    -webkit-animation-name: spin;
    -webkit-animation-duration: 10000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 10000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 10000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: spin;
    animation-duration: 10000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`

const ReachOutTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  margin: auto;
  max-width: 150px;
`

const ReachOutText = styled.div`
  display: block;
  max-width: 130px;
  font-style: normal;
  font-weight: ${props => props.theme.fontweight};;
  font-size: 30.57px;
  line-height: 35px;
  letter-spacing: -0.02em;
  margin-bottom: 50px;
  color: ${props => props.theme.color};
`

const ReachOutButton = styled.button`
  border: 1px solid rgb(116, 81, 220);
  background-color: ${props => props.theme.background};
  cursor: pointer;
  padding: 12px 20px;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 13px;
  line-height: 116%;
  letter-spacing: 0.05em;
  color: rgb(116, 81, 220);
  transition: background-color 250ms ease 0s, color 250ms ease 0s;
  :hover, :focus {
    background-color: rgb(116, 81, 220);
    border: 1px solid rgb(116, 81, 220);
    color: white;
  }
`

export { ReachOutSection,
  ReachOutContainer,
  ReachOutTextContainer,
  ReachOutText,
  ReachOutButton
 }; 