import { createGlobalStyle } from "styled-components";


const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Open Sans', sans-serif;
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    transition: all 0.50s linear; 
  }
`

export default GlobalStyles;