import React from "react";
import { HashLink } from 'react-router-hash-link';
import { CopyrightSection,
  CopyrightDisclosure,
  CopyRightLogo,
  CopyrightIcons
 } from "../styles/CopyrightSection";
 import logo from '../assets/GiniLogoBlack.svg'

const CopyRight = () => {
  return (
    <CopyrightSection>
      <CopyRightLogo>
        <HashLink smooth to='/#hero'>
          <img src={logo} />
        </HashLink>
      </CopyRightLogo>
      <CopyrightDisclosure>
        Disclosures
      </CopyrightDisclosure>
      <CopyrightIcons>
        <a href="https://twitter.com/">
          <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.35493 14.0646C11.3925 14.0646 14.6962 8.89681 14.6962 4.42288C14.6962 4.2775 14.6962 4.13211 14.6898 3.98672C15.3301 3.51091 15.8871 2.90954 16.3289 2.22887C15.7399 2.49982 15.106 2.67825 14.4401 2.76416C15.1188 2.34783 15.6374 1.68037 15.8871 0.887356C15.2533 1.27725 14.549 1.55481 13.7999 1.7068C13.198 1.04596 12.3465 0.63623 11.4053 0.63623C9.59343 0.63623 8.12084 2.15618 8.12084 4.02637C8.12084 4.29071 8.15285 4.54844 8.20407 4.79956C5.47658 4.66079 3.05641 3.30605 1.43656 1.25743C1.15485 1.75967 0.994784 2.34122 0.994784 2.96241C0.994784 4.13872 1.57742 5.17625 2.45457 5.78423C1.91676 5.7644 1.41095 5.6124 0.969174 5.36128C0.969174 5.3745 0.969174 5.38772 0.969174 5.40754C0.969174 7.04644 2.10243 8.421 3.60063 8.7316C3.32532 8.8109 3.0372 8.85055 2.73628 8.85055C2.525 8.85055 2.32012 8.83073 2.12164 8.79107C2.5378 10.1392 3.75429 11.1173 5.18846 11.1437C4.06161 12.0557 2.64665 12.5976 1.11003 12.5976C0.847526 12.5976 0.58502 12.5844 0.328918 12.5513C1.7759 13.5029 3.50459 14.0646 5.35493 14.0646Z" fill="#18181B"></path>
          </svg>
        </a>
        <a href="https://medium.com/">
          <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.22621 3.37559C2.24609 3.16796 2.17119 2.96273 2.02453 2.82299L0.530599 0.920426V0.63623H5.16926L8.75469 8.94905L11.9069 0.63623H16.3289V0.920426L15.0516 2.21512C14.9415 2.30385 14.8868 2.4497 14.9097 2.59405V12.1068C14.8868 12.2512 14.9415 12.397 15.0516 12.4858L16.299 13.7804V14.0646H10.0245V13.7804L11.3168 12.4542C11.4437 12.32 11.4437 12.2805 11.4437 12.0753V4.38608L7.85086 14.0331H7.36533L3.18233 4.38608V10.8516C3.14745 11.1235 3.23285 11.3971 3.41389 11.5937L5.09456 13.7489V14.0331H0.328918V13.7489L2.00959 11.5937C2.18931 11.3968 2.26973 11.1213 2.22621 10.8516V3.37559Z" fill="#18181B"></path>
          </svg>
        </a>
      </CopyrightIcons>

    </CopyrightSection>
  )
}

export default CopyRight;