import React, { useState } from "react";
import styled from "styled-components";

const SwitchDark = ({ checked = true, color = "#097CFF", toggleTheme }) => {
  const [toggle, setToggle] = useState(checked);

  return (
    <Switch>
      <Input {...{ color }} type="checkbox" defaultChecked={toggle} />
      <Slider {...{ toggle, color }} onClick={() => {
        setToggle(!toggle);
        toggleTheme();
        }} />
    </Switch>
  );
};

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ toggle, color }) => (toggle ? color : "#FAFAFC")};
  border-radius: 15px;
  transition: 0.4s;

  &:before {
    content: "";

    position: absolute;
    left: 2px;
    bottom: 2px;

    width: 20px;
    height: 20px;
    border-radius: 100%;

    background-color: ${({ toggle, color }) => (toggle ? "#FAFAFC" : color)};

    transition: 0.4s;
  }
`;

const Input = styled.input`
  &:checked + ${Slider}:before {
    transform: translateX(23.4px);
  }
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  background-color: ${({ toggle, color }) => (toggle ? color : "#FAFAFC")};
  border-radius: 15px;
  transition: 0.4s;

  & ${Input} {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

export default SwitchDark;