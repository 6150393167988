import styled from "styled-components";

const BodyContainer = styled.div`
  width: 100vw;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
`;

const BodyFlexContainer = styled.div`
//--vh
  min-height: calc(var(10.54,1vh) * 100);
  max-height: calc(var(10.54,1vh) * 100);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export { BodyContainer, BodyFlexContainer };