import React from "react";
import { HashLink } from 'react-router-hash-link';
import { NavContainerBottom, NavElement } from "../styles/NavContainer";
import SwitchDark from "./SwitchDark";

const Footer = (props) => {
  return (
    <NavContainerBottom>
        <HashLink smooth to='/#blog'>
          <NavElement>
            Blog/News
          </NavElement>
        </HashLink>
          <SwitchDark toggleTheme={props.toggleTheme}/>        
        <HashLink smooth to='/#contact'>
          <NavElement>
            Contact
          </NavElement>
        </HashLink>
    </NavContainerBottom>
  )
};

export default Footer;