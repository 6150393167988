export const lightTheme = {
  background: '#FAFAFC',
  color: '#181818',
  backgroundhover: '#FAFAFC',
  disclosurecolor: '#181818',
  fontweight: 600,
  //buttonBg: '#c5718d'
}

export const darkTheme = {
  background: '#181818',
  color: '#FAFAFC',
  backgroundhover: '#09090A',
  disclosurecolor: '#181818',
  fontweight: 600,
  //buttonBg: '#515d90'
}