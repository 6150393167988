import React from "react";
import PortfolioElement from "./PortfolioElement";
import { PortfolioSection,
  PortfolioText,
  PortfolioImages
} from "../styles/PortfolioSection";
import ethereum from "../assets/icn-ethereum.svg";
import ethereumDark from "../assets/ethereumDark.png";


const Portfolio = (props) => {
  return (
    <PortfolioSection id="portfolio">
      <PortfolioText>
        Our portfolio
      </PortfolioText>
      <PortfolioImages>
        <PortfolioElement 
          link={"https://ethereum.org/de/"}
          image={props.isDarkTheme ? ethereum : ethereumDark}
          text={"Ethereum"}
        />
        <PortfolioElement 
          link={"https://ethereum.org/de/"}
          image={props.isDarkTheme ? ethereum : ethereumDark}
          text={"Ethereum"}
        />
        <PortfolioElement 
          link={"https://ethereum.org/de/"}
          image={props.isDarkTheme ? ethereum : ethereumDark}
          text={"Ethereum"}
        />
        <PortfolioElement 
          link={"https://ethereum.org/de/"}
          image={props.isDarkTheme ? ethereum : ethereumDark}
          text={"Ethereum"}
        />
        <PortfolioElement 
          link={"https://ethereum.org/de/"}
          image={props.isDarkTheme ? ethereum : ethereumDark}
          text={"Ethereum"}
        />
        <PortfolioElement 
          link={"https://ethereum.org/de/"}
          image={props.isDarkTheme ? ethereum : ethereumDark}
          text={"Ethereum"}
        />
        <PortfolioElement 
          link={"https://ethereum.org/de/"}
          image={props.isDarkTheme ? ethereum : ethereumDark}
          text={"Ethereum"}
        />
        <PortfolioElement 
          link={"https://ethereum.org/de/"}
          image={props.isDarkTheme ? ethereum : ethereumDark}
          text={"Ethereum"}
        />
        <PortfolioElement 
          link={"https://ethereum.org/de/"}
          image={props.isDarkTheme ? ethereum : ethereumDark}
          text={"Ethereum"}
        />
        <PortfolioElement 
          link={"https://ethereum.org/de/"}
          image={props.isDarkTheme ? ethereum : ethereumDark}
          text={"Ethereum"}
        />
      </PortfolioImages>
    </PortfolioSection>
  )
}

export default Portfolio;