import React, { Fragment, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { BodyContainer, BodyFlexContainer } from './styles/BodyContainer';
import { ContentContainer, ContentMobilePadding } from './styles/ContentContainer';

import { ThemeProv } from './components/ThemeContext';

import Nav from './components/Nav';
import Footer from './components/Footer';
import Home from './components/Home';
import Vlog from './components/Vlog';

import { ThemeProvider } from "styled-components";
import GlobalStyles from "./components/GlobalStyles";
import { lightTheme, darkTheme } from "./styles/Themes";


function App() {
  const [theme, setTheme] = useState("light");
  const isDarkTheme = theme === "dark";

  const toggleTheme = () => {
    setTheme(isDarkTheme ? "light" : "dark");
    localStorage.setItem("theme", theme);
  }

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    const prefersDark =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;
    if (savedTheme && ["dark", "light"].includes(savedTheme)) {
      setTheme(savedTheme);
    } else if (prefersDark) {
      setTheme("dark");
    }
  }, []);

  return (
    <Router>
      <Fragment>
        <ThemeProvider theme={isDarkTheme ? darkTheme : lightTheme}>
          <GlobalStyles />
          
          <BodyContainer>
            <BodyFlexContainer>
              <Nav isDarkTheme={isDarkTheme}/>
              <ContentContainer>
                <ContentMobilePadding>
                  <ThemeProv>
                    <Routes>
                      <Route path='/' exact element={<Home isDarkTheme={isDarkTheme}/>} />
                      <Route path='/vlog/:id' exact element={<Vlog/>} />
                    </Routes>
                  </ThemeProv>
                </ContentMobilePadding>
              </ContentContainer>

              <Footer toggleTheme={toggleTheme} isDarkTheme={isDarkTheme}/>
            </BodyFlexContainer>
          </BodyContainer>
        </ThemeProvider>
      </Fragment>
    </Router>
  );
}

export default App;
