import styled from "styled-components";

const AboutSection = styled.div`
  min-height: calc((var(10.54,1vh) * 100) - (75px * 2));
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
`;

const AboutContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  gap: 50px;

  @media (max-width: 960px) {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
`;

const AboutLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;

const OurMissionText = styled.div`
  display: block;
  margin-top: 100px;
  margin-bottom: 25px;
  font-weight: bold;
  //font-family: Univers;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgb(116, 81, 220);
  text-align: left;
`

const AboutBigText = styled.div`
  display: flex;
  overflow: hidden;
  //font-family: "Circular Std";
  font-style: normal;
  font-weight: ${props => props.theme.fontweight};;
  font-size: 30.57px;
  line-height: 35px;
  letter-spacing: -0.02em;
  max-width: 500px;
  color: ${props => props.theme.color};
  text-align: left;
`

const AboutRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  -webkit-box-pack: end;
  justify-content: flex-end;
`;

export { AboutSection, AboutContainer, AboutLeftColumn, OurMissionText, AboutBigText, AboutRightColumn };

