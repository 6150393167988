import styled from "styled-components";

const NavContainerTop = styled.div`
  padding-right: 40px;
  padding-left: 40px;
  min-height: 75px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: ${props => props.theme.background};
  @media (max-width: 575px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`;

const NavContainerBottom = styled.div`
  padding-right: 40px;
  padding-left: 40px;
  min-height: 75px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: ${props => props.theme.background};
  @media (max-width: 575px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const NavElementWrapper = styled.div`
  position: relative; 
`

const NavElement = styled.div`
  position: relative;
  font-size: 16px;//13px;
  font-weight: bold;
  color: ${props => props.theme.color};
  line-height: 15px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 8px;
  text-align: center;
  letter-spacing: 0.05em;
  transition: background-color 250ms ease 0s, color 250ms ease 0s;
  :hover {
    background-color: rgb(93, 67, 172);
  }
  img {
    height: 30px;
    width: 30px;
  }
`

export { NavContainerTop, NavElementWrapper, NavElement, NavContainerBottom };