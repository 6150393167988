import React from "react";
import logo from '../assets/GiniLogoCircle.svg';
import logoDark from '../assets/GiniLogoCircleDark.svg';
import { ReachOutSection,
  ReachOutContainer,
  ReachOutTextContainer,
  ReachOutText,
  ReachOutButton
 } from "../styles/ReachOutSection";

const ReachOut = (props) => {
  return (
    <ReachOutSection id="contact">
      <ReachOutContainer>
        {props.isDarkTheme ? (
            <img src={logo} alt='logo'/>
          ) : (
            <img src={logoDark} alt='logo'/>
          )
        }
        <ReachOutTextContainer>
          <ReachOutText>
            Reach out and say hello
          </ReachOutText>
          <a href="mailto:hello@gini.ventures">
            <ReachOutButton>
              Email us
            </ReachOutButton>
          </a>
        </ReachOutTextContainer>
      </ReachOutContainer>
    </ReachOutSection>
  )
}

export default ReachOut;