import styled from "styled-components";

const PortfolioSection = styled.div`
  min-height: calc((var(10.54,1vh) * 100) - (75px * 2));
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 200px;
`;

const PortfolioText = styled.div`
  //font-family: Univers;
  font-weight: bold;
  font-size: 15px;
  line-height: 15px;
  margin-top: 100px;
  margin-bottom: 60px;
  text-align: left;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgb(116, 81, 220);
`

const PortfolioImages = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0px, 1fr));

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }
  @media (min-width: 972px) {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  }
  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, minmax(0px, 1fr));
  }
`

const PortfolioImageWrapper = styled.div`
  width: 190px;
  height: 190px;
  margin: auto;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  transition: all 500ms ease-in-out 0s;
  border-radius: 4px;
  :hover {
    background: ${props => props.theme.backgroundhover};
    box-shadow: rgba(0, 0, 0, 0.75) 0px 4px 24px;
  }
  img {
    max-width: 40%;
    max-height: 40%;
    object-fit: contain;
  }
  a {
    
    
  }
  

  /*
  a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    img {
      max-width: 40%;
      max-height: 40%;
      object-fit: contain;
    }
    
    :hover {
      background: rgb(9, 9, 10);
      box-shadow: rgba(0, 0, 0, 0.75) 0px 4px 24px;
    }
  }
  */
`

const PortfolioImageLabel = styled.div`
  //font-family: Univers;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  //opacity: 0;
  transition: opacity 500ms ease-in-out 0s;
  position: absolute;
  bottom: 15%;
  width: 100%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgb(116, 81, 220);
  
  /*
  :hover {
    opacity: 1;
  }
  */

  

`

export { PortfolioSection, 
  PortfolioText, 
  PortfolioImages,
  PortfolioImageWrapper,
  PortfolioImageLabel
};