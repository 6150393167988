import React from "react";
import { PortfolioSection,
  PortfolioText,
  PortfolioImages,
  PortfolioImageWrapper,
  PortfolioImageLabel
} from "../styles/PortfolioSection";


const PortfolioElement = ({link, image, text}) => {
  return (
    <a href={link} target="blank" >
      <PortfolioImageWrapper>
        <img alt="Portfolio" src={image}/>
        <PortfolioImageLabel>
          {text}
        </PortfolioImageLabel>
      </PortfolioImageWrapper>
    </a>
  )
}

export default PortfolioElement;