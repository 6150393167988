import React from "react";
import Hero from './Hero';
import About from './About';
import Portfolio from './Portfolio';
import Blog from './Blog';
import ReachOut from './ReachOut';
import CopyRight from './Copyright';


const Home = (props) => {
  return (
    <div>
      <Hero/>
      <About/>
      <Portfolio isDarkTheme={props.isDarkTheme}/>
      <Blog/>
      <ReachOut isDarkTheme={props.isDarkTheme}/>
      <CopyRight/>
    </div>
  )
}

export default Home; 