import React, { useEffect } from "react";
import headerimg from '../assets/Dalle2Cropped.png';
import { VlogContainer, 
    VlogTitle, 
    VlogDateAuthor,
    VlogContent,
    VlogFigure,
    VlogParagraph,
    VlogHeadline3,
    VlogHeadline2,
    VlogOl,
    VlogBottom,
    VlogDisclosures
  } from '../styles/VlogStyles';

const Vlog = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <VlogContainer>
      <VlogTitle>
        Web3 is Better Than Free!
      </VlogTitle>
      <VlogDateAuthor>
        August 30, 2022 — FirstName LastName
      </VlogDateAuthor>
      <VlogContent>
        <div>
          <VlogFigure>
            <img src={headerimg} alt="Blabla" />
          </VlogFigure>
          <VlogParagraph>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod 
            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At 
            vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, 
            no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, 
            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et 
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo 
            dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem 
            ipsum dolor sit amet.
          </VlogParagraph>
          <VlogHeadline2>
            Major Findings
          </VlogHeadline2>
          <VlogParagraph>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod 
            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At 
            vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, 
            no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, 
            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et 
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo 
            dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem 
            ipsum dolor sit amet.
          </VlogParagraph>
          <VlogOl>
            <li>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.
            </li>
            <li>
              At vero eos et accusam et justo duo dolores et ea rebum.
            </li>
            <li>
              Stet clita kasd gubergren, no sea takimata sanctus est Lorem.
            </li>
          </VlogOl>
          <VlogParagraph>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod 
            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At 
            vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, 
            no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, 
            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et 
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo 
            dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem 
            ipsum dolor sit amet.
          </VlogParagraph>
          <VlogHeadline3>
            Technology
          </VlogHeadline3>
          <VlogParagraph>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod 
            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At 
            vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, 
            no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, 
            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et 
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo 
            dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem 
            ipsum dolor sit amet.
          </VlogParagraph>
          <VlogFigure>
            <img src={headerimg} alt="Blabla" />
          </VlogFigure>
          <VlogParagraph>
              <strong>
                This is a strong image text
              </strong>
          </VlogParagraph>
          <VlogParagraph>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod 
            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At 
            vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, 
            no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, 
            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et 
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo 
            dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem 
            ipsum dolor sit amet.
          </VlogParagraph>
          <VlogParagraph>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod 
            tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At 
            vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, 
            no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, 
            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et 
            dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo 
            dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem 
            ipsum dolor sit amet.
          </VlogParagraph>
          <VlogBottom/>
      
          <VlogDisclosures href="/disclosures">
            Disclosures
          </VlogDisclosures>
        </div>
      </VlogContent>

    </VlogContainer>
  )
}


export default Vlog;