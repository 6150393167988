import React from "react";
import { useNavigate } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import { 
  BlogSectionArticleWrapper,
  BlogSectionArticleDate,
  BlogSectionArticleTitle,
  BlogSectionArticleArrowContainer
  } from "../styles/BlogSection"

const BlogSectionArticle = ({date, title, url}) => {
  const navigate = useNavigate()
  const handleClick = (blogID) => {
    navigate(`/vlog/${blogID}`)
  }
  return (
    <BlogSectionArticleWrapper>
        <BlogSectionArticleDate>
          {date}
        </BlogSectionArticleDate>
        <BlogSectionArticleTitle onClick={() => handleClick(url)}>
          {title}
        </BlogSectionArticleTitle>
        <BlogSectionArticleArrowContainer>
          <svg width="38" height="9" viewBox="0 0 38 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M37.0834 4.72868C37.2786 4.53341 37.2786 4.21683 37.0834 4.02157L33.9014 0.839588C33.7061 0.644326 33.3895 0.644326 33.1943 0.839588C32.999 1.03485 32.999 1.35143 33.1943 1.54669L36.0227 4.37512L33.1943 7.20355C32.999 7.39881 32.999 7.71539 33.1943 7.91066C33.3895 8.10592 33.7061 8.10592 33.9014 7.91066L37.0834 4.72868ZM0.203918 4.87512H36.7298V3.87512H0.203918V4.87512Z" fill="#7451DC"></path></svg>
        </BlogSectionArticleArrowContainer>
    </BlogSectionArticleWrapper>
  )
}

export default BlogSectionArticle;
